.el_scroll {
  display: flex;
  gap: 5px;
  position: fixed;
  top: 50%;
  left: 13.3vw;
  transform: translateY(-50%);
  z-index: 100;
  mix-blend-mode: difference;
  @include mqMax() {
    display: none;
  }

  &_line {
    display: block;
    width: 1px;
    height: 140px;
    background-color: #fff;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &_txt {
    letter-spacing: 0.19em;
    writing-mode: vertical-lr;
    color: #fff;
    font-style: italic;
    font-weight: 400;
    mix-blend-mode: difference;
  }
}
