// Font
// --------------------------------------------------
$jp_font: 'Noto Serif JP', serif;
$accent_font: 'Montserrat', sans-serif;
$second_font: 'Noto Sans JP', sans-serif;

// Transition
// --------------------------------------------------
$trsi: 0.4s ease-in;
$animetion: 0.65s cubic-bezier(0.25, 1, 0.5, 1);

// Dir
// --------------------------------------------------
$dirpath: '../img';
$dp-common: '#{$dirpath}/common';
$dp-top: '#{$dirpath}/top';
$dp-vision: '#{$dirpath}/vision';
$dp-keyword: '#{$dirpath}/keyword';
$dp-kyoto: '#{$dirpath}/kyoto';
$dp-gradu: '#{$dirpath}/gradu';
$dp-career: '#{$dirpath}/career';
$dp-limited: '#{$dirpath}/limited';
$dp-realwork: '#{$dirpath}/realwork';
// lr
// --------------------------------------------------
$sp-lr: 2.7vw;
$sp-lr2: 6vw;

// 継承変数
// --------------------------------------------------
%path-shadow {
  filter: drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.35));
}
%path-shadow2 {
  filter: drop-shadow(20px 20px 20px rgba(0, 0, 0, 0.25));
}

$minWidth_s: 1200px;
$minWidth_m: 1200px;
$minWidth_l: 1920px;

$font-10: 2.65vw;
$font-12: 3.2vw;
// $font-14: 3.5vw;
$font-15: 4vw;
$font-16: 4.27vw;
$font-20: 5.4vw;
$font-28: 7.5vw;
