.el_hdg {
  letter-spacing: 0.1em;
  font-size: 2.8rem;
  font-weight: 500;
  @include mqMax() {
    line-height: 1.7;
    font-size: VwMax(18);
  }

  span {
    display: block;
    margin-bottom: 10px;
    letter-spacing: 0.2em;
    color: #b3a680;
    font-family: $second_font;
    font-size: 0.571em;
    font-weight: 400;
    @include mqMax() {
      font-size: VwMax(12);
    }
  }
}
