// メディアクエリ
// --------------------------------------------------
@mixin mqMax($breakpoint: m) {
  @if $breakpoint == sss {
    @media screen and (max-width: 350px) {
      @content;
    }
  } @else if $breakpoint == ss {
    @media screen and (max-width: 400px) {
      @content;
    }
  } @else if $breakpoint == s {
    @media screen and (max-width: 500px) {
      @content;
    }
  } @else if $breakpoint == m {
    @media screen and (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint {
    @media screen and (max-width: $breakpoint + px) {
      @content;
    }
  }
}

@mixin mqMin($breakpoint) {
  @if $breakpoint {
    @media screen and (min-width: $breakpoint + px) {
      @content;
    }
  }
}

@mixin mqMaxH($breakpoint) {
  @if $breakpoint {
    @media screen and (max-height: $breakpoint + px) {
      @content;
    }
  }
}

@mixin mqMinH($breakpoint) {
  @if $breakpoint {
    @media screen and (min-height: $breakpoint + px) {
      @content;
    }
  }
}

// ホバー
// --------------------------------------------------
@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

// vw設定やclamp設定
// --------------------------------------------------
@function px-to-rem($px, $base-font-size: 16) {
  @return calc(#{$px} / #{$base-font-size} * 1rem);
}

@function px-to-vw($px, $view-port-width: 375) {
  @return calc(#{$px} / #{$view-port-width} * 100vw);
}

@function Clamp($min, $max, $min-view-port: 375, $max-view-port: 768) {
  $variable-part: calc(($max - $min) / ($max-view-port - $min-view-port));
  $constant: calc(($max - $max-view-port * #{$variable-part}) / 16);
  $min-rem: px-to-rem($min);
  $max-rem: px-to-rem($max);
  $variable-rem: calc(#{$constant} * 1rem);
  $variable-vw: calc(100 * #{$variable-part} * 1vw);

  @return clamp(
    #{$min-rem},
    calc(#{$variable-rem} + #{$variable-vw}),
    #{$max-rem}
  );
}

@function Px($value) {
  @return px-to-rem($value);
}

@function VwMin($value, $view-port-width: 1440) {
  $vw-value: px-to-vw($value, $view-port-width);
  @return unquote('min(#{$value}px, #{$vw-value})');
}

@function VwMax($value, $view-port-width: 375) {
  $vw-value: px-to-vw($value, $view-port-width);
  @return unquote('max(#{$value}px, #{$vw-value})');
}

// vw設定やclamp設定
// --------------------------------------------------
@mixin cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
