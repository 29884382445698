.ly_header {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 100;
  @include mqMax() {
    width: 100%;
  }

  &_inner {
    display: flex;
    align-items: center;
    gap: 43px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px 0;
    padding-left: 15px;
    @include mqMax() {
      align-items: flex-start;
      flex-direction: column;
      gap: 0;
      padding: VwMax(14) VwMax(30) VwMax(8);
    }
  }

  &_logo {
    @include mqMax() {
      width: VwMax(190);
    }

    a {
      display: grid;
    }

    img {
      @include mqMax() {
        width: 100%;
      }
    }
  }

  &_info {
    letter-spacing: 0.04em;
    color: #079c63;
    font-family: $accent_font;
    font-size: 1.6rem;
    font-weight: 700;
    @include mqMax() {
      margin-top: 3px;
      font-size: VwMax(10);
    }
  }
}
