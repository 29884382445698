*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  color: #323232;
  font-family: $jp_font;
  font-optical-sizing: auto;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  @include mqMax() {
    font-size: VwMax(16);
  }
  // @include mqMin(1921) {
  //   font-size: 0.89vw;
  // }
  // @include mqMax() {
  //   background: url(#{$dp-common}/sp_bg.jpg) repeat;
  // }
}

em {
  font-style: normal;
}

ul,
ol {
  list-style: none;
}

img {
  max-width: 100%;
  vertical-align: top;
}

a {
  color: inherit;
}

.is_hidden {
  overflow: hidden;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::placeholder {
  color: #d2d6db;
  font-size: 1.4rem;
}

.is_fadeIn {
  opacity: 0;

  &.start {
    animation: fadeInPaint 3s ease forwards;

    @keyframes fadeInPaint {
      0% {
        opacity: 0;
        // transform: translateY(10px);
      }

      100% {
        opacity: 1;
        // transform: translateY(0);
      }
    }
  }
}

.is_fadeInDown {
  opacity: 0;

  &.start {
    animation: fadeInDownPaint 3s ease forwards;

    @keyframes fadeInDownPaint {
      0% {
        opacity: 0;
        transform: translateY(-20px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
