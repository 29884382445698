.ly_footer {
  margin-top: 200px;
  padding: 0 40px 70px;
  text-align: center;
  @include mqMax() {
    width: 100%;
    margin-top: VwMax(83);
    padding: VwMax(50) VwMax(30) VwMax(46);
    border-top: 1px solid #000;
  }

  &_box {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;

    .list {
      display: flex;
      gap: 24px;
      @include mqMax() {
        display: none;
      }
    }

    a {
      transition: $animetion;
      @include hover() {
        opacity: 0.6;
      }
    }

    .kyoto {
      @include mqMax() {
        width: 100%;
        max-width: VwMax(295);
        margin: 0 auto;
      }

      img {
        @include mqMax() {
          width: 100%;
        }
      }
    }

    .shop {
      @include mqMax() {
        display: none;
      }
    }
  }

  .btn {
    margin-top: 36px;
    @include mqMax() {
      display: none;
    }

    a {
      display: inline-block;
      padding: 10px 21px;
      line-height: 1.6;
      font-family: $second_font;
      border: 1px solid #000;
      border-radius: 3px;
      transition: $animetion;
      @include hover() {
        background-color: #000;
        color: #fff;
      }
    }
  }

  .tel {
    margin-top: 36px;
    font-size: 1.9rem;
    font-weight: 600;
    @include mqMax() {
      display: none;
    }
  }

  .access {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    @include mqMax() {
      display: none;
    }
  }

  .logo {
    margin-top: 40px;
    @include mqMax() {
      max-width: VwMax(169);
      margin: VwMax(35) auto 0;
    }

    a {
      transition: $animetion;
      @include hover() {
        opacity: 0.6;
      }
    }

    img {
      @include mqMax() {
        width: 100%;
      }
    }
  }

  &_copy {
    margin-top: 22px;
    font-size: 1.4rem;
    @include mqMax() {
      margin-top: VwMax(26);
      font-size: VwMax(12);
    }
  }
}
