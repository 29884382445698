.un_integration {
  &_mv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100svh - 104px);
    text-align: center;
    background: url(#{$dirpath}/mv.jpg) no-repeat center / cover;
    @include mqMax() {
      width: 100%;
      height: calc(100svh - VwMax(50));
      background: url(#{$dirpath}/mv_sp.jpg) no-repeat center / cover;
    }

    &_inner {
      .hdg {
        opacity: 0;
        transform: translateY(40px);
        @include mqMax() {
          width: VwMax(300);
        }

        img {
          width: 100%;
        }
      }

      .txt {
        margin: 4.3vw 0;
        opacity: 0;
        transform: translateY(40px);
        @include mqMax() {
          margin: VwMax(40) 0 VwMax(34);
        }

        svg {
          width: 438px;
          @include mqMax() {
            width: VwMax(257);
          }
        }
      }

      .btn {
        margin-top: 37px;
        opacity: 0;
        transform: translateY(40px);
        @include mqMax() {
          width: VwMax(250);
          margin: VwMax(30) auto 0;
        }

        a {
          transition: $animetion;
          @include hover() {
            opacity: 0.6;
          }
        }

        img {
          @include mqMax() {
            width: 100%;
          }
        }
      }
    }
  }

  &_bl01 {
    padding: 90px 40px 125px;
    @include mqMax() {
      padding: VwMax(60) 0 VwMax(100);
    }

    &_message {
      display: flex;
      justify-content: center;
      gap: 27px;
      @include mqMax() {
        flex-wrap: wrap;
        gap: VwMax(35);
        padding: 0 VwMax(30);
      }

      .hdg {
        margin-top: 5px;
        @include mqMax() {
          width: 100%;
          max-width: VwMax(124);
          margin-top: 0;
        }

        img {
          width: 100%;
        }
      }

      .txt {
        max-width: 524px;
        line-height: 1.8;
        @include mqMax() {
          max-width: 100%;
        }
      }
    }

    &_list {
      display: flex;
      gap: 40px;
      max-width: 1440px;
      margin: 86px auto 0;
      @include mqMax() {
        flex-wrap: wrap;
        gap: 0;
        margin-top: VwMax(56);
      }

      li {
        width: 50%;
        height: 550px;
        @include mqMax() {
          width: 100%;
          height: VwMax(400);
        }

        a {
          display: grid;
          place-content: center;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          transition: $animetion;
          @include hover() {
            opacity: 0.6;
          }

          .ttl {
            padding-top: 120px;
            @include mqMax() {
              max-width: VwMax(250);
              padding-top: 0;
            }
          }

          .btn {
            max-width: 160px;
            margin: 77px auto 0;
            @include mqMax() {
              width: 100%;
              max-width: VwMax(250);
              margin-top: VwMax(30);
            }

            span {
              display: block;
              padding: 7px 45px;
              text-align: center;
              letter-spacing: 0.019em;
              color: #fff;
              font-family: $second_font;
              font-size: 1.3rem;
              border: 1px solid #fff;
              border-radius: 50px;
              @include mqMax() {
                padding: 10px;
                font-size: VwMax(13);
              }
            }
          }
        }

        &:nth-child(1) {
          a {
            background-image: url(#{$dirpath}/bg_kyoto.jpg);
          }
        }

        &:nth-child(2) {
          a {
            background-image: url(#{$dirpath}/bg_hakone.jpg);
          }
        }
      }
    }
  }

  &_bl02 {
    padding: 0 40px;
    @include mqMax() {
      padding: 0;
    }

    &_inner {
      max-width: 1440px;
      margin: 0 auto;

      + .un_integration_bl02_inner {
        margin-top: 80px;
        @include mqMax() {
          margin-top: 0;
        }
      }
    }

    &_ttl {
      display: flex;
      align-items: center;
      gap: 34px;
      padding: 11px;
      position: relative;
      @include mqMax() {
        flex-wrap: wrap;
        justify-content: center;
        gap: VwMax(5);
        padding: VwMax(16) VwMax(30) VwMax(52);
      }

      &::after {
        @include mqMax() {
          content: '';
          display: block;
          width: 12px;
          height: 12px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          position: absolute;
          bottom: 24px;
          left: 50%;
          transform: rotate(-45deg) translateX(-50%) scale(-1, -1);
          @include mqMax() {
            width: VwMax(12);
            height: VwMax(12);
            bottom: VwMax(24);
          }
        }
      }

      em {
        display: block;
        padding: 12px 22px;
        color: #fff;
        font-size: 2.5rem;
        border: 1px solid #fff;
        @include mqMax() {
          padding: 0 12px;
          font-size: VwMax(16);
        }
      }

      .hdg {
        color: #fff;
        font-size: 3rem;
        font-weight: 400;
        @include mqMax() {
          width: 100%;
          text-align: center;
          line-height: 1.2;
          font-size: VwMax(25);
        }
      }

      &.kyoto {
        background-color: #0f432f;

        + .un_integration_bl02_navs {
          @include mqMax() {
            padding-bottom: VwMax(50);
          }
        }
      }

      &.hakone {
        background-color: #1c3558;
      }

      &.is_open {
        &::after {
          transform: rotate(-45deg) translateX(-50%) scale(1, 1);
        }
      }
    }

    &_navs {
      display: flex;
      gap: 20px;
      padding: 40px 20px;
      @include mqMax() {
        display: none;
        flex-wrap: wrap;
        gap: VwMax(33);
        padding: VwMax(28) VwMax(30) 0;
      }

      .list {
        flex: 1;
        @include mqMax() {
          flex: auto;
        }

        .ttl {
          line-height: 1.44;
          font-size: 2.5rem;
          font-weight: 400;
          @include mqMax() {
            font-size: VwMax(20);
          }
        }

        ul {
          margin-top: 20px;
          @include mqMax() {
            margin-top: VwMax(20);
          }

          li {
            a {
              display: inline-flex;
              align-items: center;
              gap: 8px;
              line-height: 1.87;
              text-decoration: underline;
              color: #707070;
              font-family: $second_font;
              transition: $animetion;
              @include mqMax() {
                gap: VwMax(5);
                font-size: VwMax(12);
              }
              @include hover() {
                opacity: 0.6;
              }

              &.icon_link {
                &::after {
                  content: '';
                  display: block;
                  width: 12px;
                  height: 12px;
                  background: url(#{$dirpath}/icon_link.svg) no-repeat center /
                    100% 100%;
                  @include mqMax() {
                    width: VwMax(9);
                    height: VwMax(9);
                  }
                }
              }
            }

            + li {
              margin-top: 15px;
              @include mqMax() {
                margin-top: VwMax(20);
              }
            }
          }
        }
      }
    }
  }
}
