// デスクトップ幅だけ表示
.lg_only {
  display: block !important;
  @include mqMax() {
    display: none !important;
  }
}

.lg_md_only {
  display: block !important;
  @include mqMax(s) {
    display: none !important;
  }
}

// タブレット幅以下だけ表示
.md_only {
  display: none !important;
  @include mqMax() {
    display: block !important;
  }
  @include mqMax(s){
    display: none !important;
  }
}

.md_sm_only {
  display: none !important;
  @include mqMax() {
    display: block !important;
  }
}

// スマホ幅以下だけ表示
.sm_only {
  display: none !important;
  @include mqMax(s) {
    display: block !important;
  }
}
