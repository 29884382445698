html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  color: #323232;
  font-family: "Noto Serif JP", serif;
  font-optical-sizing: auto;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  body {
    font-size: max(16px, calc(16 / 375 * 100vw));
  }
}

em {
  font-style: normal;
}

ul,
ol {
  list-style: none;
}

img {
  max-width: 100%;
  vertical-align: top;
}

a {
  color: inherit;
}

.is_hidden {
  overflow: hidden;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::placeholder {
  color: #d2d6db;
  font-size: 1.4rem;
}

.is_fadeIn {
  opacity: 0;
}
.is_fadeIn.start {
  animation: fadeInPaint 3s ease forwards;
}
@keyframes fadeInPaint {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.is_fadeInDown {
  opacity: 0;
}
.is_fadeInDown.start {
  animation: fadeInDownPaint 3s ease forwards;
}
@keyframes fadeInDownPaint {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ly_footer {
  margin-top: 200px;
  padding: 0 40px 70px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .ly_footer {
    width: 100%;
    margin-top: max(83px, calc(83 / 375 * 100vw));
    padding: max(50px, calc(50 / 375 * 100vw)) max(30px, calc(30 / 375 * 100vw)) max(46px, calc(46 / 375 * 100vw));
    border-top: 1px solid #000;
  }
}
.ly_footer_box {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}
.ly_footer_box .list {
  display: flex;
  gap: 24px;
}
@media screen and (max-width: 768px) {
  .ly_footer_box .list {
    display: none;
  }
}
.ly_footer_box a {
  transition: 0.65s cubic-bezier(0.25, 1, 0.5, 1);
}
@media (hover: hover) and (pointer: fine) {
  .ly_footer_box a:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 768px) {
  .ly_footer_box .kyoto {
    width: 100%;
    max-width: max(295px, calc(295 / 375 * 100vw));
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px) {
  .ly_footer_box .kyoto img {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .ly_footer_box .shop {
    display: none;
  }
}
.ly_footer .btn {
  margin-top: 36px;
}
@media screen and (max-width: 768px) {
  .ly_footer .btn {
    display: none;
  }
}
.ly_footer .btn a {
  display: inline-block;
  padding: 10px 21px;
  line-height: 1.6;
  font-family: "Noto Sans JP", sans-serif;
  border: 1px solid #000;
  border-radius: 3px;
  transition: 0.65s cubic-bezier(0.25, 1, 0.5, 1);
}
@media (hover: hover) and (pointer: fine) {
  .ly_footer .btn a:hover {
    background-color: #000;
    color: #fff;
  }
}
.ly_footer .tel {
  margin-top: 36px;
  font-size: 1.9rem;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .ly_footer .tel {
    display: none;
  }
}
.ly_footer .access {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .ly_footer .access {
    display: none;
  }
}
.ly_footer .logo {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .ly_footer .logo {
    max-width: max(169px, calc(169 / 375 * 100vw));
    margin: max(35px, calc(35 / 375 * 100vw)) auto 0;
  }
}
.ly_footer .logo a {
  transition: 0.65s cubic-bezier(0.25, 1, 0.5, 1);
}
@media (hover: hover) and (pointer: fine) {
  .ly_footer .logo a:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 768px) {
  .ly_footer .logo img {
    width: 100%;
  }
}
.ly_footer_copy {
  margin-top: 22px;
  font-size: 1.4rem;
}
@media screen and (max-width: 768px) {
  .ly_footer_copy {
    margin-top: max(26px, calc(26 / 375 * 100vw));
    font-size: max(12px, calc(12 / 375 * 100vw));
  }
}

.ly_header {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 100;
}
@media screen and (max-width: 768px) {
  .ly_header {
    width: 100%;
  }
}
.ly_header_inner {
  display: flex;
  align-items: center;
  gap: 43px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 0;
  padding-left: 15px;
}
@media screen and (max-width: 768px) {
  .ly_header_inner {
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
    padding: max(14px, calc(14 / 375 * 100vw)) max(30px, calc(30 / 375 * 100vw)) max(8px, calc(8 / 375 * 100vw));
  }
}
@media screen and (max-width: 768px) {
  .ly_header_logo {
    width: max(190px, calc(190 / 375 * 100vw));
  }
}
.ly_header_logo a {
  display: grid;
}
@media screen and (max-width: 768px) {
  .ly_header_logo img {
    width: 100%;
  }
}
.ly_header_info {
  letter-spacing: 0.04em;
  color: #079c63;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .ly_header_info {
    margin-top: 3px;
    font-size: max(10px, calc(10 / 375 * 100vw));
  }
}

.el_hdg {
  letter-spacing: 0.1em;
  font-size: 2.8rem;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .el_hdg {
    line-height: 1.7;
    font-size: max(18px, calc(18 / 375 * 100vw));
  }
}
.el_hdg span {
  display: block;
  margin-bottom: 10px;
  letter-spacing: 0.2em;
  color: #b3a680;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.571em;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .el_hdg span {
    font-size: max(12px, calc(12 / 375 * 100vw));
  }
}

.el_scroll {
  display: flex;
  gap: 5px;
  position: fixed;
  top: 50%;
  left: 13.3vw;
  transform: translateY(-50%);
  z-index: 100;
  mix-blend-mode: difference;
}
@media screen and (max-width: 768px) {
  .el_scroll {
    display: none;
  }
}
.el_scroll_line {
  display: block;
  width: 1px;
  height: 140px;
  background-color: #fff;
  position: relative;
}
.el_scroll_line::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.el_scroll_txt {
  letter-spacing: 0.19em;
  writing-mode: vertical-lr;
  color: #fff;
  font-style: italic;
  font-weight: 400;
  mix-blend-mode: difference;
}

.el_txt {
  line-height: 2.2;
  font-size: 1.8rem;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .el_txt {
    font-size: max(12px, calc(12 / 375 * 100vw));
  }
}

.un_integration_mv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100svh - 104px);
  text-align: center;
  background: url(../img/mv.jpg) no-repeat center/cover;
}
@media screen and (max-width: 768px) {
  .un_integration_mv {
    width: 100%;
    height: calc(100svh - max(50px, calc(50 / 375 * 100vw)));
    background: url(../img/mv_sp.jpg) no-repeat center/cover;
  }
}
.un_integration_mv_inner .hdg {
  opacity: 0;
  transform: translateY(40px);
}
@media screen and (max-width: 768px) {
  .un_integration_mv_inner .hdg {
    width: max(300px, calc(300 / 375 * 100vw));
  }
}
.un_integration_mv_inner .hdg img {
  width: 100%;
}
.un_integration_mv_inner .txt {
  margin: 4.3vw 0;
  opacity: 0;
  transform: translateY(40px);
}
@media screen and (max-width: 768px) {
  .un_integration_mv_inner .txt {
    margin: max(40px, calc(40 / 375 * 100vw)) 0 max(34px, calc(34 / 375 * 100vw));
  }
}
.un_integration_mv_inner .txt svg {
  width: 438px;
}
@media screen and (max-width: 768px) {
  .un_integration_mv_inner .txt svg {
    width: max(257px, calc(257 / 375 * 100vw));
  }
}
.un_integration_mv_inner .btn {
  margin-top: 37px;
  opacity: 0;
  transform: translateY(40px);
}
@media screen and (max-width: 768px) {
  .un_integration_mv_inner .btn {
    width: max(250px, calc(250 / 375 * 100vw));
    margin: max(30px, calc(30 / 375 * 100vw)) auto 0;
  }
}
.un_integration_mv_inner .btn a {
  transition: 0.65s cubic-bezier(0.25, 1, 0.5, 1);
}
@media (hover: hover) and (pointer: fine) {
  .un_integration_mv_inner .btn a:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 768px) {
  .un_integration_mv_inner .btn img {
    width: 100%;
  }
}
.un_integration_bl01 {
  padding: 90px 40px 125px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl01 {
    padding: max(60px, calc(60 / 375 * 100vw)) 0 max(100px, calc(100 / 375 * 100vw));
  }
}
.un_integration_bl01_message {
  display: flex;
  justify-content: center;
  gap: 27px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl01_message {
    flex-wrap: wrap;
    gap: max(35px, calc(35 / 375 * 100vw));
    padding: 0 max(30px, calc(30 / 375 * 100vw));
  }
}
.un_integration_bl01_message .hdg {
  margin-top: 5px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl01_message .hdg {
    width: 100%;
    max-width: max(124px, calc(124 / 375 * 100vw));
    margin-top: 0;
  }
}
.un_integration_bl01_message .hdg img {
  width: 100%;
}
.un_integration_bl01_message .txt {
  max-width: 524px;
  line-height: 1.8;
}
@media screen and (max-width: 768px) {
  .un_integration_bl01_message .txt {
    max-width: 100%;
  }
}
.un_integration_bl01_list {
  display: flex;
  gap: 40px;
  max-width: 1440px;
  margin: 86px auto 0;
}
@media screen and (max-width: 768px) {
  .un_integration_bl01_list {
    flex-wrap: wrap;
    gap: 0;
    margin-top: max(56px, calc(56 / 375 * 100vw));
  }
}
.un_integration_bl01_list li {
  width: 50%;
  height: 550px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl01_list li {
    width: 100%;
    height: max(400px, calc(400 / 375 * 100vw));
  }
}
.un_integration_bl01_list li a {
  display: grid;
  place-content: center;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.65s cubic-bezier(0.25, 1, 0.5, 1);
}
@media (hover: hover) and (pointer: fine) {
  .un_integration_bl01_list li a:hover {
    opacity: 0.6;
  }
}
.un_integration_bl01_list li a .ttl {
  padding-top: 120px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl01_list li a .ttl {
    max-width: max(250px, calc(250 / 375 * 100vw));
    padding-top: 0;
  }
}
.un_integration_bl01_list li a .btn {
  max-width: 160px;
  margin: 77px auto 0;
}
@media screen and (max-width: 768px) {
  .un_integration_bl01_list li a .btn {
    width: 100%;
    max-width: max(250px, calc(250 / 375 * 100vw));
    margin-top: max(30px, calc(30 / 375 * 100vw));
  }
}
.un_integration_bl01_list li a .btn span {
  display: block;
  padding: 7px 45px;
  text-align: center;
  letter-spacing: 0.019em;
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.3rem;
  border: 1px solid #fff;
  border-radius: 50px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl01_list li a .btn span {
    padding: 10px;
    font-size: max(13px, calc(13 / 375 * 100vw));
  }
}
.un_integration_bl01_list li:nth-child(1) a {
  background-image: url(../img/bg_kyoto.jpg);
}
.un_integration_bl01_list li:nth-child(2) a {
  background-image: url(../img/bg_hakone.jpg);
}
.un_integration_bl02 {
  padding: 0 40px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02 {
    padding: 0;
  }
}
.un_integration_bl02_inner {
  max-width: 1440px;
  margin: 0 auto;
}
.un_integration_bl02_inner + .un_integration_bl02_inner {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_inner + .un_integration_bl02_inner {
    margin-top: 0;
  }
}
.un_integration_bl02_ttl {
  display: flex;
  align-items: center;
  gap: 34px;
  padding: 11px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_ttl {
    flex-wrap: wrap;
    justify-content: center;
    gap: max(5px, calc(5 / 375 * 100vw));
    padding: max(16px, calc(16 / 375 * 100vw)) max(30px, calc(30 / 375 * 100vw)) max(52px, calc(52 / 375 * 100vw));
  }
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_ttl::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%) scale(-1, -1);
  }
}
@media screen and (max-width: 768px) and (max-width: 768px) {
  .un_integration_bl02_ttl::after {
    width: max(12px, calc(12 / 375 * 100vw));
    height: max(12px, calc(12 / 375 * 100vw));
    bottom: max(24px, calc(24 / 375 * 100vw));
  }
}
.un_integration_bl02_ttl em {
  display: block;
  padding: 12px 22px;
  color: #fff;
  font-size: 2.5rem;
  border: 1px solid #fff;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_ttl em {
    padding: 0 12px;
    font-size: max(16px, calc(16 / 375 * 100vw));
  }
}
.un_integration_bl02_ttl .hdg {
  color: #fff;
  font-size: 3rem;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_ttl .hdg {
    width: 100%;
    text-align: center;
    line-height: 1.2;
    font-size: max(25px, calc(25 / 375 * 100vw));
  }
}
.un_integration_bl02_ttl.kyoto {
  background-color: #0f432f;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_ttl.kyoto + .un_integration_bl02_navs {
    padding-bottom: max(50px, calc(50 / 375 * 100vw));
  }
}
.un_integration_bl02_ttl.hakone {
  background-color: #1c3558;
}
.un_integration_bl02_ttl.is_open::after {
  transform: rotate(-45deg) translateX(-50%) scale(1, 1);
}
.un_integration_bl02_navs {
  display: flex;
  gap: 20px;
  padding: 40px 20px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_navs {
    display: none;
    flex-wrap: wrap;
    gap: max(33px, calc(33 / 375 * 100vw));
    padding: max(28px, calc(28 / 375 * 100vw)) max(30px, calc(30 / 375 * 100vw)) 0;
  }
}
.un_integration_bl02_navs .list {
  flex: 1;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_navs .list {
    flex: auto;
  }
}
.un_integration_bl02_navs .list .ttl {
  line-height: 1.44;
  font-size: 2.5rem;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_navs .list .ttl {
    font-size: max(20px, calc(20 / 375 * 100vw));
  }
}
.un_integration_bl02_navs .list ul {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_navs .list ul {
    margin-top: max(20px, calc(20 / 375 * 100vw));
  }
}
.un_integration_bl02_navs .list ul li a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  line-height: 1.87;
  text-decoration: underline;
  color: #707070;
  font-family: "Noto Sans JP", sans-serif;
  transition: 0.65s cubic-bezier(0.25, 1, 0.5, 1);
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_navs .list ul li a {
    gap: max(5px, calc(5 / 375 * 100vw));
    font-size: max(12px, calc(12 / 375 * 100vw));
  }
}
@media (hover: hover) and (pointer: fine) {
  .un_integration_bl02_navs .list ul li a:hover {
    opacity: 0.6;
  }
}
.un_integration_bl02_navs .list ul li a.icon_link::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: url(../img/icon_link.svg) no-repeat center/100% 100%;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_navs .list ul li a.icon_link::after {
    width: max(9px, calc(9 / 375 * 100vw));
    height: max(9px, calc(9 / 375 * 100vw));
  }
}
.un_integration_bl02_navs .list ul li + li {
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  .un_integration_bl02_navs .list ul li + li {
    margin-top: max(20px, calc(20 / 375 * 100vw));
  }
}

.lg_only {
  display: block !important;
}
@media screen and (max-width: 768px) {
  .lg_only {
    display: none !important;
  }
}

.lg_md_only {
  display: block !important;
}
@media screen and (max-width: 500px) {
  .lg_md_only {
    display: none !important;
  }
}

.md_only {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .md_only {
    display: block !important;
  }
}
@media screen and (max-width: 500px) {
  .md_only {
    display: none !important;
  }
}

.md_sm_only {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .md_sm_only {
    display: block !important;
  }
}

.sm_only {
  display: none !important;
}
@media screen and (max-width: 500px) {
  .sm_only {
    display: block !important;
  }
}